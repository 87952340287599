import getters from './getters';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: {
    SUPPORT_VIDEO_CODEC: [],
  },
}));

describe('getters', () => {
  describe('alarmList', () => {
    it('should parse alarm.alarms and return', () => {
      const state = {
        alarm: {
          alarms: {
            0: { name: 'alarm1' },
            1: { name: 'alarm2' },
            2: { name: 'alarm3' },
          }
        }
      };
      const groupValues = [
        {
          id: 0,
          text: 'alarm1',
          value: 'alarm1',
        },
        {
          id: 1,
          text: 'alarm2',
          value: 'alarm2',
        },
        {
          id: 2,
          text: 'alarm3',
          value: 'alarm3',
        }
      ];
      expect(getters.alarmList(state)).toEqual([
        {
          groupLabel: 'Alarm list',
          groupValues
        },
      ]);
    });
  });
  describe('activeVideo', () => {
    it('should give alarm data by videoMap', () => {
      const state = {
        currentType: 'test',
        test: {
          activeVideoId: 999
        }
      };
      const theGetters = {
        videoMap: {
          999: {
            name: 'motion',
          },
        },
      };
      expect(getters.activeVideo(state, theGetters).name).toEqual('motion');
    });
  });
  describe('nextPlayableChannel', () => {
    it('should return next channel from play list', () => {
      const state = {
        currentType: 'test',
        test: {
          playingChannel: 1,
          playList: [
            {
              channel: 1,
            },
            {
              channel: 3,
            }
          ],
        }
      };
      expect(getters.nextPlayableChannel(state)).toEqual(3);
    });
    it('should return -1 if meet the end of play list', () => {
      const state = {
        currentType: 'test',
        test: {
          playingChannel: 3,
          playList: [
            {
              channel: 1,
            },
            {
              channel: 3,
            }
          ],
        }
      };
      expect(getters.nextPlayableChannel(state)).toEqual(-1);
    });
  });
  describe('activeMap', () => {
    it('should transfer alarm array into map by id', () => {
      const state = {
        currentType: 'test',
        test: {
          result: [
            {
              id: 1,
              name: 'abc',
            },
            {
              id: 2,
              name: 'motion',
            },
            {
              id: 3,
              name: 'di',
            },
            {
              id: 4,
              name: 'temp',
            },
          ],
        }
      };
      expect(getters.videoMap(state)[4].name).toEqual('temp');
    });
  });
  describe('activeView', () => {
    it('should return default viewcell', () => {
      const state = {
        currentType: 'test',
        test: {
          playingChannel: -1,
          playList: [],
        }
      };
      expect(getters.activeView(state).channel).toEqual(-1);
    });

    it('should return playing viewcell', () => {
      const state = {
        currentType: 'test',
        test: {
          playingChannel: 3,
          playList: [
            {
              $_id: 999,
              channel: 2,
            },
            {
              $_id: 1000,
              channel: 3,
            },
          ],
        }
      };
      expect(getters.activeView(state).$_id).toEqual(1000);
    });
  });
  describe('pageSize', () => {
    it('should filteredSearchResultData length = 24 , return 2', () => {
      const state = {
        currentType: 'test',
        test: {
          MAX_PAGE_COUNT: 12
        }
      };
      const getter = {
        filteredDataLength: 24
      };
      expect(getters.pageSize(state, getter)).toEqual(2);
    });
    it('should filteredSearchResultData length = 0 , return 1', () => {
      const state = {
        currentType: 'test',
        test: {
          MAX_PAGE_COUNT: 12
        }
      };
      const getter = {
        filteredDataLength: 0
      };
      expect(getters.pageSize(state, getter)).toEqual(1);
    });
    it('should filteredSearchResultData length = 12 , return 1', () => {
      const state = {
        currentType: 'test',
        test: {
          MAX_PAGE_COUNT: 12
        }
      };
      const getter = {
        filteredDataLength: 12
      };

      expect(getters.pageSize(state, getter)).toEqual(1);
    });
  });
  describe('nextPlayableVideoId', () => {
    describe('alarm nextPlayableVideoId', () => {
      it('should return first alarm id which has recording clips camera', () => {
        const state = {
          currentType: 'alarm',
          alarm: {
            activeVideoId: -1,
            result: [{
              id: 1,
              relation_cameras: [],
              recording_data: 0
            }, {
              id: 2,
              relation_cameras: [1, 2, 3],
              recording_data: 0
            }, {
              id: 3,
              relation_cameras: [4, 5],
              recording_data: 1
            }]
          }
        };

        expect(getters.nextPlayableVideoId(state)).toEqual(3);
      });
      it('should return next playable alarm id in result', () => {
        const state = {
          currentType: 'alarm',
          alarm: {
            activeVideoId: 1,
            result: [{
              relation_cameras: [1],
              id: 0,
            }, {
              relation_cameras: [1, 2, 3],
              id: 1,
            }, {
              relation_cameras: [4, 5],
              id: 2,
            }]
          }
        };

        expect(getters.nextPlayableVideoId(state)).toEqual(2);
      });
    });
    describe('vca nextPlayableVideoId', () => {
      it('should return first vca id which has recording clips camera', () => {
        const state = {
          currentType: 'vca',
          vca: {
            activeVideoId: -1,
            result: [{
              id: 1,
            }, {
              id: 2,
            }, {
              id: 3,
            }]
          }
        };

        expect(getters.nextPlayableVideoId(state)).toEqual(1);
      });
      it('should return next playable vca id in result', () => {
        const state = {
          currentType: 'vca',
          vca: {
            activeVideoId: 1,
            result: [{
              id: 0,
            }, {
              id: 1,
            }, {
              id: 2,
            }]
          }
        };

        expect(getters.nextPlayableVideoId(state)).toEqual(2);
      });
    });
  });

  describe('triggerOptions', () => {
    it('should return triggerOptions', () => {
      const rootState = {
        system: {
          capability: {
            support_business_intelligence: true,
            support_buzzer: true,
            support_cam_tmis_events: true,
            support_cam_vca_event_events: true,
            support_cms: true,
            support_customer_support: true,
            support_driver_change_notification_action: false,
            support_lte: false,
            support_nvr_brute_force_attack_event: true,
            support_nvr_cyber_attack_event: true,
            support_nvr_di_event: true,
            support_nvr_disk_error_event: true,
            support_nvr_raid_degraded_event: true,
            support_nvr_do_event: true,
            support_nvr_fan_event: false,
            support_nvr_g_sensor_event: false,
            support_nvr_gps_disconnect_event: false,
            support_nvr_gps_overspeed_event: false,
            support_nvr_netlink_down_event: false,
            support_nvr_poe_event: true,
            support_nvr_quarantine_event_event: true,
            support_panic_notification_action: false,
            support_push_notification_action: true,
            support_tmis: true,
            support_tmis_expiration_check: true,
            support_wifi: false,
          },
          info:{
            brand:'VIVOTEK'
          }
        }
      };
      const result = [{
        groupLabel: 'System trigger',
        groupValues: [
          { text: 'DI', value: 'nvr_di' },
          { text: 'DO', value: 'nvr_do' },
          { text: 'Disk failure', value: 'disk_error' },
          { text: 'Disk full', value: 'storage_full' },
          { text: 'RAID degraded', value: 'raid_degraded'},
          { text: 'Cyber attack', value: 'nvr_cyber_attk' },
          { text: 'Brute force attack', value: 'nvr_brute_force_attk' },
          { text: 'PoE error', value: 'poe_event' },
          { text: 'Quarantine event', value: 'nvr_quarantine_evt' },
          { text: 'Caution occupancy reached', value: 'caution_occupancy_reached' },
          { text: 'Full occupancy reached', value: 'full_occupancy_reached' },
          { text: 'Full occupancy clear', value: 'full_occupancy_clear' }]
      },
      {
        groupLabel: 'Camera trigger',
        groupValues: [{ text: 'Motion detection', value: 'motion' },
          { text: 'PIR', value: 'pir' },
          { text: 'Tampering detection', value: 'tampering' },
          { text: 'Camera disconnected', value: 'video_lost' },
          { text: 'Video loss (Video server only)', value: 'vs_video_loss' },
          { text: 'Camera DI', value: 'cam_di' },
          { text: 'Camera DO', value: 'cam_do' },
          { text: 'Brute force attack', value: 'cam_brute_force_attk' },
          { text: 'Cyber attack', value: 'cam_cyber_attk' },
          { text: 'Quarantine event', value: 'cam_quarantine_evt' },
          { text: 'Line crossing detection', value: 'cam_line_crossing_detct' },
          { text: 'Crowd detection', value: 'cam_crowd_detct' },
          { text: 'Unattended object detection', value: 'cam_unattended_obj_detct' },
          { text: 'Missing object detection', value: 'cam_missing_obj_detct' },
          { text: 'Face detection', value: 'cam_face_detct' },
          { text: 'Loitering detection', value: 'cam_loitering_detct' },
          { text: 'Intrusion detection', value: 'cam_intrusion_detct' },
          { text: 'Running detection', value: 'cam_running_detct' },
          { text: 'Restricted zone detection', value: 'cam_restricted_zone_detct' },
          { text: 'Parking violation detection', value: 'cam_parking_violation_detct' },
          { text: 'Smart tracking advanced', value: 'cam_smart_tracking_detct' }]

      }];
      const rootGetters = {
        'system/systemCapability': {
          support_tmis: true,
          support_crowd_control: true,
          support_video_server_video_loss_event: true,
        }
      };
      expect(getters.triggerOptions({}, {}, rootState, rootGetters)).toEqual(result);
    });
  });

  describe('isExporting', () => {
    it('should return true if state.status is EXPORTING', () => {
      const STATE = {
        exportStatus: 'EXPORTING'
      };
      expect(getters.isExporting(STATE)).toBe(true);
    });
    it('should return false if state.status is NORMAL', () => {
      const STATE = {
        exportStatus: 'NORMAl'
      };
      expect(getters.isExporting(STATE)).toBe(false);
    });
  });
  describe('isExportingError', () => {
    it('should return true ', () => {
      const STATE = {
        exportStatus: 'ERROR'
      };
      expect(getters.isExportingError(STATE)).toBe(true);
    });
    it('should return false', () => {
      const STATE = {
        exportStatus: 'NORMAL'
      };
      expect(getters.isExportingError(STATE)).toBe(false);
    });
  });
  describe('hasSearchedResult', () => {
    it('should return true if currentResult getter is more than one item array', () => {
      const STATE = {};
      const getter = {
        currentResult: [{}]
      };

      expect(getters.hasSearchedResult(STATE, getter)).toBe(true);
    });
  });
  describe('isSearching', () => {
    it('should return true if status is loading', () => {
      const STATE = {
        status: 'loading'
      };
      expect(getters.isSearching(STATE)).toBe(true);
    });
    it('should return false if status is initial', () => {
      const STATE = {
        status: 'intial'
      };
      expect(getters.isSearching(STATE)).toBe(false);
    });
  });
});
